import {
  CreateHouseholdRequest,
  UpdateHouseholdRequest,
  OptionalEmailCreateHouseholdRequest,
  OptionalEmailUpdateHouseholdRequest,
} from "@compoundfinance/compound-core";
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { DateTime, Duration } from "luxon";
import { FullHousehold, GetHouseholdResponse, Note } from "./models";
import { NoteCreate, NoteType, NoteUpdate } from "./validation";
import { Account } from "feature/accounts/models";
import { DefaultAPIParams } from "feature/filtersV2/models";
import { prospectKeys } from "feature/prospects/queries";
import { Routes } from "feature/routes/";
import { userKeys } from "feature/user/queries";
import { useAppContext } from "providers/AppContextProvider";

export const defaultHouseholdPageSize = 100;

export const defaultQueryHHKeys: DefaultAPIParams = {
  filter: undefined,
  ascFields: [],
  descFields: [],
  includeDescendantOrganizations: true,
};

const formatListKey = (fields: string[] = []) => {
  if (fields.length === 0) {
    return "-";
  }
  return fields.join("-");
};

export const householdKeys = {
  all: ["households"] as const,
  detail: (id: string) => [...householdKeys.all, "detail", id] as const,
  accounts: (id: string) => [...householdKeys.all, id, "accounts"] as const,
  notes: (id: string) => [...householdKeys.all, id, "notes"] as const,
  note: (id: string, noteId: string) => [...householdKeys.all, id, "notes", noteId] as const,
  filtered: (apiParams: DefaultAPIParams) => {
    const { filter, ascFields, descFields, includeDescendantOrganizations } = apiParams;
    const ascFieldKey = formatListKey(ascFields);
    const descFieldKey = formatListKey(descFields);
    return [
      ...householdKeys.all,
      {
        filter: filter ?? {},
        ascFieldKey,
        descFieldKey,
        includeDescendantOrganizations,
      },
    ] as const;
  },
  id: (id: string) => ["household", id] as const,
};

interface PageParam {
  readonly cursor?: string;
  readonly lastSortedByValue?: string; // TODO: Deprecate
}

const getPagedHouseholds = async (
  filters: DefaultAPIParams,
  url: string,
  pageParam?: PageParam
): Promise<GetHouseholdResponse> => {
  const resp = await axios.request<GetHouseholdResponse>({
    method: Routes.API.AccountGroup.List.method,
    url,
    params: {
      cursor: pageParam?.cursor,
      ...filters,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
  return resp.data;
};

export const useHouseholdsQuery = (
  apiParams: DefaultAPIParams
): UseInfiniteQueryResult<InfiniteData<GetHouseholdResponse>> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.List;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useInfiniteQuery({
    queryKey: householdKeys.filtered(apiParams),
    queryFn: ({ pageParam }) => getPagedHouseholds(apiParams, url, pageParam),
    getNextPageParam: (lastPage) =>
      lastPage.cursor
        ? {
            cursor: lastPage.cursor,
            lastSortedByValue: lastPage.lastSortedByValue,
          }
        : lastPage.cursor,
    placeholderData: keepPreviousData,
    retry: 1,
    staleTime: Duration.fromDurationLike({ minutes: 5 }).as("milliseconds"),
    initialPageParam: {},
  });
};

export const useHouseholdQuery = (
  householdId: string,
  isEnabled: boolean = true
): UseQueryResult<FullHousehold> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.Id;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useQuery({
    queryKey: householdKeys.detail(householdId),
    queryFn: async () => {
      const result = await axios.request<FullHousehold>({
        method: routeConfig.method,
        url,
      });
      return result.data;
    },
    enabled: isEnabled,
  });
};

export const useCreateHouseholdMutation = () => {
  const { featureFlags, selectedTeams } = useAppContext();
  const queryClient = useQueryClient();
  const routeConfig = Routes.API.AccountGroup.Create;

  return useMutation({
    mutationFn: (formData: CreateHouseholdRequest | OptionalEmailCreateHouseholdRequest) => {
      return axios.request<
        CreateHouseholdRequest | OptionalEmailCreateHouseholdRequest,
        AxiosResponse<FullHousehold>
      >({
        url: Routes.Utils.apiRouteWithTeam({
          url: routeConfig.getUrl(),
          scope: routeConfig.scope,
          selectedTeams,
          featureFlagEnabled: featureFlags["teams-access-control"] === true,
          scopeIdOverride: formData.teamId,
        }),
        method: routeConfig.method,
        data: formData,
      });
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: userKeys.clients(),
        }),
        queryClient.invalidateQueries({
          queryKey: prospectKeys.all,
        }),
        queryClient.invalidateQueries({ queryKey: householdKeys.all }),
      ]);
    },
  });
};

export const useUpdateHouseholdMutation = () => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.Update;

  return useMutation({
    mutationFn: (formData: UpdateHouseholdRequest | OptionalEmailUpdateHouseholdRequest) => {
      return axios.request<
        UpdateHouseholdRequest | OptionalEmailUpdateHouseholdRequest,
        AxiosResponse<FullHousehold>
      >({
        url: Routes.Utils.apiRouteWithTeam({
          url: routeConfig.getUrl(formData.id),
          scope: routeConfig.scope,
          selectedTeams,
          featureFlagEnabled: featureFlags["teams-access-control"] === true,
        }),
        method: routeConfig.method,
        data: formData,
      });
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: userKeys.clients(),
        }),
        queryClient.invalidateQueries({
          queryKey: prospectKeys.all,
        }),
        queryClient.invalidateQueries({ queryKey: householdKeys.all }),
      ]);
    },
  });
};

export const useDeleteHouseholdMutation = (householdId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.Delete;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useMutation({
    mutationFn: () => {
      return axios.request({
        url,
        method: routeConfig.method,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: householdKeys.all,
      });
    },
  });
};

export const useNoteCreateMutation = (householdId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Notes.Create;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    scopeIdOverride: householdId,
  });

  return useMutation({
    mutationFn: (formData: NoteCreate) =>
      axios.request<Note>({
        url,
        method: routeConfig.method,
        data: formData,
      }),

    onSettled: (data, error, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: householdKeys.notes(variables.groupId),
        }),
        queryClient.invalidateQueries({
          queryKey: householdKeys.detail(variables.groupId),
        }),
        queryClient.invalidateQueries({
          queryKey: householdKeys.all,
        }),
      ]);
    },
  });
};

export const useNoteUpdateMutation = (householdId: string, noteId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Notes.Update;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, noteId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    scopeIdOverride: householdId,
  });

  return useMutation({
    mutationFn: (formData: NoteUpdate) =>
      axios.request<Note>({
        url,
        method: routeConfig.method,
        data: formData,
      }),

    onMutate: async (formData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: householdKeys.notes(householdId) });

      // Snapshot the current cache
      const currentNotes = queryClient.getQueryData<Note[]>(householdKeys.notes(householdId));

      const outgoingNotes = currentNotes?.filter((n) => n.id === noteId) ?? [];

      const [staleNote] = outgoingNotes;
      if (staleNote && currentNotes) {
        // Call Note specific logic
        const newNote: Note = {
          ...staleNote,
          text: formData.notes,
          lastEditedOn: formData.lastEditedOn.toISOString(),
        };

        queryClient.setQueryData(
          householdKeys.notes(householdId),
          [...currentNotes.filter((n) => n.id !== noteId).slice(), newNote].sort(
            (a: Note, b: Note) =>
              DateTime.fromISO(b.createdOn).valueOf() - DateTime.fromISO(a.createdOn).valueOf()
          )
        );
      }
      return { currentNotes };
    },

    onSettled: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: householdKeys.notes(householdId),
        }),
        queryClient.invalidateQueries({
          queryKey: householdKeys.detail(householdId),
        }),
        queryClient.invalidateQueries({
          queryKey: householdKeys.all,
        }),
      ]);
    },
  });
};

const getHouseholdNotes = async (url: string, noteType: "General" | "Call" = "Call") => {
  const resp = await axios.request<Note[]>({
    method: Routes.API.Notes.List.method,
    url: `${url}?type=${noteType}`,
  });
  return resp.data;
};

export const useHouseholdNotesQuery = (
  householdId: string,
  noteType: NoteType
): UseQueryResult<Note[]> => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Notes.List;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    scopeIdOverride: householdId,
  });

  return useQuery({
    queryKey: householdKeys.notes(householdId),
    queryFn: () => getHouseholdNotes(url, noteType),
    // https://tanstack.com/query/v4/docs/guides/initial-query-data#initial-data-from-cache
    initialData: () => {
      // First check if this detail view already has a cache
      const notes = queryClient.getQueryData<Note[]>(householdKeys.notes(householdId));
      const household = queryClient.getQueryData<FullHousehold>(householdKeys.detail(householdId));
      // If cache is already initialized, skip don't bother here
      if (!notes && household) {
        return household.callNotes.filter((n) => {
          return n.noteType === noteType;
        });
      }
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(householdKeys.detail(householdId))?.dataUpdatedAt,
    placeholderData: keepPreviousData,
    staleTime: Duration.fromDurationLike({ hour: 1 }).toMillis(),
  });
};

const getHouseholdNote = async (url: string) => {
  const resp = await axios.request<Note>({
    method: Routes.API.Notes.Get.method,
    url: `${url}`,
  });
  return resp.data;
};

export const useHouseholdNoteQuery = (
  householdId: string,
  noteId: string,
  enabled: boolean = true
): UseQueryResult<Note> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Notes.Get;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, noteId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
    scopeIdOverride: householdId,
  });

  return useQuery({
    queryKey: householdKeys.note(householdId, noteId),
    queryFn: () => getHouseholdNote(url),
    enabled,
  });
};

export const useHouseholdAccountsQuery = (
  householdId: string,
  isEnabled: boolean = true
): UseQueryResult<Account[]> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.AccountGroup.Accounts;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useQuery({
    queryKey: householdKeys.accounts(householdId),
    queryFn: async () => {
      const result = await axios.request<Account[]>({
        method: routeConfig.method,
        url,
      });
      return result.data;
    },
    enabled: isEnabled,
  });
};
