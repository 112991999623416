import { FeatureRoute } from "./models";

type AccountApiNames = "Id" | "List" | "Search" | "Update";
type AccountGroupApiNames =
  | "BulkCreate"
  | "Create"
  | "CreateDashboard"
  | "Delete"
  | "Id"
  | "List"
  | "Search"
  | "Update"
  | "Accounts"; // Should we allow for some level of nesting here if we wish to move to something more restful?
type AdminApiNames = "AccountGroupSearch" | "EmployeeList";
type ClientApiNames = "Delete" | "Id" | "List" | "SendDashboardInvitation" | "Update";
type EmployeeApiNames = "Id" | "List";
type InvestmentModelApiNames = "List" | "Export";
type InvestmentStrategyApiNames = "List" | "Export";
type MeetingNoteApiNames =
  | "EmailResponse"
  | "GenerateEmailResponse"
  | "ListHeaders"
  | "Id"
  | "Note"
  | "Search";
type NoteApiNames = "Get" | "Create" | "List" | "Update";
type OnboardingApiNames = "AddEnvelope" | "Get" | "List" | "UpdateEnvelope";
type PlanApiNames = "List";
type ProposalApiNames =
  | "Create"
  | "Delete"
  | "Id"
  | "List"
  | "Name"
  | "Performance"
  | "PrivateFunds"
  | "Section"
  | "SecurityProxy"
  | "Send"
  | "Summary"
  | "HouseholdAccounts";
type ProspectApiNames = "List" | "Update";
type TeamApiNames =
  | "Admin"
  | "Create"
  | "List"
  | "Get"
  | "Update"
  | "AddMember"
  | "GetMember"
  | "RemoveMember"
  | "Migrate"
  | "Roles";
type UserApiNames = "Teams";
type FirefliesApiNames = "Refresh";

export interface ApiRoutes {
  readonly Account: FeatureRoute<AccountApiNames>;
  readonly AccountGroup: FeatureRoute<AccountGroupApiNames>;
  readonly Admin: FeatureRoute<AdminApiNames>;
  readonly Client: FeatureRoute<ClientApiNames>;
  readonly Employee: FeatureRoute<EmployeeApiNames>;
  readonly InvestmentModel: FeatureRoute<InvestmentModelApiNames>;
  readonly InvestmentStrategy: FeatureRoute<InvestmentStrategyApiNames>;
  readonly MeetingNotes: FeatureRoute<MeetingNoteApiNames>;
  readonly Notes: FeatureRoute<NoteApiNames>;
  readonly Onboarding: FeatureRoute<OnboardingApiNames>;
  readonly Plan: FeatureRoute<PlanApiNames>;
  readonly Proposal: FeatureRoute<ProposalApiNames>;
  readonly Prospect: FeatureRoute<ProspectApiNames>;
  readonly Team: FeatureRoute<TeamApiNames>;
  readonly User: FeatureRoute<UserApiNames>;
  readonly Fireflies: FeatureRoute<FirefliesApiNames>;
}

export const API: ApiRoutes = {
  Account: {
    Id: {
      getUrl: (householdId: string, accountId: string) =>
        `account-group/${householdId}/account/${accountId}`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "account",
      method: "GET",
      scope: "team",
    },
    Search: {
      getUrl: () => "account/search",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId: string, accountId: string) =>
        `account-group/${householdId}/account/${accountId}`,
      method: "PATCH",
      scope: "team",
    },
  },
  Admin: {
    AccountGroupSearch: {
      getUrl: (employeeId: string) => `admin/account-groups?employeeId=${employeeId}`,
      method: "GET",
      scope: "global",
    },
    EmployeeList: {
      getUrl: () => `admin/employee`,
      method: "GET",
      scope: "global",
    },
  },
  AccountGroup: {
    BulkCreate: {
      getUrl: () => "account-group/bulk",
      method: "POST",
      scope: "team",
    },
    Create: {
      getUrl: () => "account-group",
      method: "POST",
      scope: "team",
    },
    CreateDashboard: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}/create-compound-user`,
      method: "POST",
      scope: "team",
    },
    Delete: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "DELETE",
      scope: "team",
    },
    Id: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "account-group",
      method: "GET",
      scope: "team",
    },
    Search: {
      getUrl: () => "account-group/search",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "PATCH",
      scope: "team",
    },
    Accounts: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}/account`,
      method: "GET",
      scope: "team",
    },
  },
  Client: {
    Delete: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "DELETE",
      scope: "team",
    },
    Id: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "client",
      method: "GET",
      scope: "team",
    },
    SendDashboardInvitation: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}/send-compound-invitation`,
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "PATCH",
      scope: "team",
    },
  },
  Employee: {
    List: {
      getUrl: () => "admin/employee",
      method: "GET",
      scope: "global",
    },
    Id: {
      getUrl: (employeeId) => `employee/${employeeId}`,
      method: "GET",
      scope: "team",
    },
  },
  InvestmentModel: {
    Export: {
      getUrl: (modelId: string) => `investment-models/${modelId}/export`,
      method: "POST",
      scope: "team",
    },
    List: {
      getUrl: () => "investment-models",
      method: "GET",
      scope: "team",
    },
  },
  InvestmentStrategy: {
    Export: {
      getUrl: (strategyId: string) => `investment-strategies/${strategyId}/export`,
      method: "POST",
      scope: "team",
    },
    List: {
      getUrl: () => "investment-strategies",
      method: "GET",
      scope: "team",
    },
  },
  MeetingNotes: {
    ListHeaders: {
      getUrl: (householdId) => `account-group/${householdId}/meeting-logs/headers`,
      method: "GET",
      scope: "team",
    },
    Id: {
      getUrl: (householdId, meetingNoteId) =>
        `account-group/${householdId}/meeting-logs/${meetingNoteId}`,
      method: "GET",
      scope: "team",
    },
    EmailResponse: {
      getUrl: (meetingNoteId: string) => `meeting-logs/${meetingNoteId}/email-response`,
      method: "GET",
      scope: "team",
    },
    GenerateEmailResponse: {
      getUrl: (meetingNoteId: string) => `meeting-logs/${meetingNoteId}/email-response`,
      method: "POST",
      scope: "team",
    },
    Note: {
      getUrl: (meetingNoteId) => `meeting-logs/${meetingNoteId}/note`,
      method: "GET",
      scope: "team",
    },
    Search: {
      getUrl: (householdId) => `account-group/${householdId}/meeting-logs/search`,
      method: "GET",
      scope: "team",
    },
  },
  Notes: {
    Get: {
      getUrl: (householdId, noteId) => `account-group/${householdId}/call-notes/${noteId}`,
      method: "GET",
      scope: "team",
    },
    Create: {
      getUrl: (householdId) => `account-group/${householdId}/call-notes`,
      method: "POST",
      scope: "team",
    },
    List: {
      getUrl: (householdId) => `account-group/${householdId}/call-notes`,
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId, noteId) => `account-group/${householdId}/call-notes/${noteId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Onboarding: {
    AddEnvelope: {
      getUrl: (householdId: string) => `account-group/${householdId}/onboard/envelope`,
      method: "POST",
      scope: "team",
    },
    Get: {
      getUrl: (householdId: string) => `account-group/${householdId}/onboard`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "account-group/onboardings",
      method: "GET",
      scope: "team",
    },
    UpdateEnvelope: {
      getUrl: (householdId: string, envelopeId: string) =>
        `account-group/${householdId}/onboard/envelope/${envelopeId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Plan: {
    List: {
      getUrl: () => "plan",
      method: "GET",
      scope: "global",
    },
  },
  Proposal: {
    Create: {
      getUrl: () => "proposals",
      method: "POST",
      scope: "team",
    },
    Delete: {
      getUrl: (id: string) => `proposals/${id}`,
      method: "DELETE",
      scope: "team",
    },
    HouseholdAccounts: {
      getUrl: (householdId: string) =>
        `account-group/${householdId}/proposals/holdings-from-compound-accounts`,
      method: "GET",
      scope: "team",
    },
    Id: {
      getUrl: (id: string) => `proposals/${id}`,
      method: "GET",
      scope: "team",
    },
    Name: {
      getUrl: (id: string) => `proposals/${id}/name`,
      method: "PUT",
      scope: "team",
    },
    Performance: {
      getUrl: (id: string) => `proposals/${id}/performance`,
      method: "GET",
      scope: "team",
    },
    PrivateFunds: {
      getUrl: (id: string) => `proposals/${id}/private-funds`,
      method: "PUT",
      scope: "team",
    },
    Section: {
      getUrl: (id: string) => `proposals/${id}/sectionMeta`,
      method: "PATCH",
      scope: "team",
    },
    SecurityProxy: {
      getUrl: (id: string) => `proposals/${id}/security-proxies`,
      method: "PUT",
      scope: "team",
    },
    Send: {
      getUrl: (id: string) => `proposals/${id}/send`,
      method: "POST",
      scope: "team",
    },
    Summary: {
      getUrl: (id: string) => `proposals/${id}/summary`,
      method: "PATCH",
      scope: "team",
    },
    List: {
      getUrl: () => "proposals",
      method: "GET",
      scope: "team",
    },
  },
  Prospect: {
    List: {
      getUrl: () => "prospects",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId: string, prospectId: string) =>
        `account-group/${householdId}/prospects/${prospectId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Fireflies: {
    Refresh: {
      getUrl: (id: string) => `fireflies-transcripts/${id}/refresh?dryRun=false`,
      method: "POST",
      scope: "team",
      _additional_test_info: {
        skip: true,
      },
    },
  },
  Team: {
    Admin: {
      getUrl: (id: string) => `teams/${id}/admin`,
      method: "GET",
      scope: "global",
    },
    Get: {
      getUrl: (id: string) => `teams/${id}`,
      method: "GET",
      scope: "team",
    },
    Create: {
      getUrl: () => "teams",
      method: "POST",
      scope: "global",
    },
    Update: {
      getUrl: (id: string) => `teams/${id}`,
      method: "PATCH",
      scope: "global",
    },
    List: {
      getUrl: () => "teams",
      method: "GET",
      scope: "team",
    },
    AddMember: {
      getUrl: (id: string) => `teams/${id}/member`,
      method: "POST",
      scope: "global",
    },
    GetMember: {
      getUrl: (id: string, employeeId: string) => `teams/${id}/member/${employeeId}`,
      method: "GET",
      scope: "global",
    },
    RemoveMember: {
      getUrl: (id: string, employeeId: string) => `teams/${id}/member/${employeeId}`,
      method: "DELETE",
      scope: "global",
    },
    Migrate: {
      getUrl: (id: string) => `teams/${id}/migrate-account-groups`,
      method: "POST",
      scope: "global",
    },
    Roles: {
      getUrl: () => "team-roles",
      method: "GET",
      scope: "global",
    },
  },
  User: {
    Teams: {
      getUrl: () => "user/teams",
      method: "GET",
      scope: "team",
    },
  },
} as const;
